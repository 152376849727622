<template>
  <div>
    <el-row>
      <el-button type="primary" @click="brandAddOpen">新增类目</el-button>
    </el-row>
    <el-row style="margin-top: 20px">
      <el-table
          :data="spendCategoryArray"
          border
          max-height="650"
          style="width: 100%">
        <el-table-column label="类目名" align="center">
          <template #default="scope">
            <span>{{scope.row.categoryName}}</span>&nbsp;
          </template>
        </el-table-column>
        <el-table-column prop="categoryDescribe" label="类目详情" align="center"></el-table-column>
        <el-table-column label="是否激活" align="center">
          <template #default="scope">
            <el-switch
                @change="isChange(scope.row)"
                v-model="scope.row.isValid"
                :active-value="1"
                :inactive-value="0"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :disabled="!show(scope.row)">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template #default="scope">
            <el-button-group>
              <el-button size="mini" type="danger" v-if="tenantCrop === '2a31c23a-c178-441614928053489'" @click="deleteSpendCategory(scope.row)">删除</el-button>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row>
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-size="100"
          layout="prev, pager, next,total, jumper, sizes"
          :total="total">
      </el-pagination>
    </el-row>
    <brand-add v-if="brandAddState" :state="brandAddState" @close="brandAddClose"></brand-add>
  </div>
</template>

<script>
import brandAdd from "@/pages/parameter/spend/spend-category-add"
export default {
  name: "spend-category-list",
  created() {
    this.pageInit()
  },
  components: {
    brandAdd,
  },
  data() {
    return {
      spendCategoryArray: [],
      tenantCrop: localStorage.getItem("tenantCrop"),
      total: 0,
      page: 1,
      limit: 100,
      brandAddState: false,
      brandGradeState: false,
      brandEditState: false,
      brand: {},
    }
  },
  computed: {
    show() {
      return function (val) {
        return this.tenantCrop === '2a31c23a-c178-441614928053489' ? true : val.isAuth !== 1 ? true : false
      }
    },
  },
  methods: {
    pageInit() {
      this.querySpendCategoryList()
    },
    // 查询支出类目列表
    querySpendCategoryList() {
      this.$axios({
        method: "GET",
        url: "/spendCategory/spendCategoryList",
        params: {
          page:this.page,
          limit:this.limit,
          tenantCrop: this.tenantCrop
        }
      }).then(response => {
        this.spendCategoryArray = response.data.data.list
        this.total = response.data.data.total
      })
    },
    handleSizeChange(val) {
      this.limit = val
      this.querySpendCategoryList();
    },
    handleCurrentChange(val) {
      this.page =val
      this.querySpendCategoryList()
    },
    isChange(val) {
      this.$axios({
        method: "POST",
        url: "/spendCategory/updateSpendCategory",
        params: {
          id: val.id,
          isValid: val.isValid,
        }
      }).then(response => {
        let flag = response.data.code === 200
        this.$message({
          showClose: true,
          message: flag ? "修改成功" :response.data.msg,
          type: flag? 'success' :'error',
          duration: 1000,
        })
        if (flag) {
          this.querySpendCategoryList()
        }
      })
    },
    //删除类目
    auth(val) {
      this.$axios({
        method: "POST",
        url: "/styleBrand/updateStyleBrand",
        params: {
          id: val.id,
          isAuth: 1,
        }
      }).then(response => {
        let flag = response.data.code === 200
        this.$message({
          showClose: true,
          message: flag ? "认证成功" :response.data.msg,
          type: flag? 'success' :'error',
          duration: 1000,
        })
        this.querySpendCategoryList()
      })
    },
    deleteSpendCategory(val) {
      this.$alert("确认要删除<b>" + val.categoryName + "</b>类目吗?",
          "删除类目",
          {
            dangerouslyUseHTMLString: true,
            type: "warning",
            showCancelButton: true
          }).then(() => {
        this.$axios({
          method: "POST",
          url: "/spendCategory/deleteSpendCategory",
          params: {
            id: val.id,
          }
        }).then(response => {
          let flag = response.data.code === 200
          this.$message({
            showClose: true,
            message: flag ? "删除类目成功" : response.data.msg,
            type: flag ? 'success' : 'error',
            duration: 1000,
          });
          this.querySpendCategoryList()
        })
      })
    },
    brandAddOpen() {
      this.brandAddState = true
    },
    brandAddClose() {
      this.brandAddState = false
      this.querySpendCategoryList()
    },
  }
}
</script>

<style scoped>

</style>
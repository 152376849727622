<template>
  <el-dialog
      title="新增类目"
      width="30%"
      :visible.sync="states"
      :modal-append-to-body="false"
      @close="close">
    <el-form label-width="100px" :model="form" :rules="rules" ref="form">
      <el-row>
        <el-col :span="20" :offset="1">
          <el-form-item label="类目名称" prop="brandName">
            <el-input v-model="form.categoryName" placeholder="请填写类目名称"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="20" :offset="1">
          <el-form-item label="类目描述" prop="brandGrade">
            <el-input v-model="form.categoryDescribe" placeholder="请填写类目描述"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="submit">提 交</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "spend-category-add",
  created() {
    this.pageInit()
  },
  props: {
    state: {
      type: Boolean,
      required: true,
      default: true,
    }
  },
  data() {
    return {
      states: this.state,
      form: {
        categoryName: "",
        categoryDescribe: "",
      },
      rules: {
        categoryName: [{required: true, message: '请填写类目名称', trigger: 'blur'}],
        categoryDescribe: [{required: true, message: '请填写类目描述', trigger: 'change'}],
      },
    }
  },
  methods: {
    pageInit() {
    },
    close() {
      this.$emit("close");
    },
    submit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.$axios({
            method: 'POST',
            url: '/spendCategory/addSpendCategory',
            params: {
              ...this.form,
              tenantCrop: localStorage.getItem("tenantCrop")
            }
          }).then(response => {
            let flag = response.data.code === 200
            this.$message({
              showClose: true,
              message: flag ? "新增支出类目成功" :response.data.msg,
              type: flag? 'success' :'error',
              duration: 1000,
            })
            if (flag) {
              this.close()
            }
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>